import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import secureStorage from "../config/encrypt";
import { default as config } from "../config/config";
const { hasAccessPermission } = config.constants;

// check for Authorized access
const AccessDenied = lazy(() => import("../view/notfound/AccessDenied"));
const Header = lazy(() => import("../view/common/Header"));
const Login = lazy(() => import("../view/user/Login"));
const LoginOtp = lazy(() => import("../view/user/LoginOtp"));
const PageNotFound = lazy(() => import("../view/notfound/PageNotFound"));
const LostLeadTab = lazy(() => import("../view/lost-lead/LostLeadTab"));
const OutbountReportTab = lazy(
	() => import("../view/finance-report/OutbountReportTab"),
);
const PaymentReport = lazy(
	() => import("../view/Reports/payment-report/PaymentReport"),
);
const LoanIdReport = lazy(() => import("../view/Reports/LoanIdReport"));
const UserRoleManagement = lazy(
	() => import("../view/user/UserRoleManagement"),
);
const InboundReportTab = lazy(
	() => import("../view/finance-report/InboundReportTab"),
);
const OtherTransactions = lazy(
	() => import("../view/other-transactions/OtherTransactions"),
);

class CustomRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ht: 500,
			loggedIn: false,
		};
	}

	static getDerivedStateFromProps = (nextProps, state) => {
		const updatedProps = {};
		if (nextProps.loggedIn !== state.loggedIn) {
			updatedProps["loggedIn"] = nextProps.loggedIn;
		}
		return updatedProps;
	};

	componentDidMount = async () => {
		this.setState({ ht: window.innerHeight - 107 });
		window.addEventListener(
			"resize",
			() => {
				this.setState({ ht: window.innerHeight - 107 });
			},
			false,
		);
	};

	render() {
		var loginResponse = secureStorage.getItem("authUserInfo") || {};
		return (
			<Router>
				<Suspense
					fallback={
						<div className="loader is-active">Loading...</div>
					}
				>
					{Object.keys(loginResponse).length ? <Header /> : null}
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						<Route path="/logout" element={<LoginOtp />} />
						<Route
							path="/dashboard"
							element={
								hasAccessPermission("finance-report") ? (
									<OutbountReportTab />
								) : (
									<AccessDenied />
								)
							}
						/>
						<Route
							path="/inbound-report/:tab/:subtab"
							element={
								hasAccessPermission("finance-report") ? (
									<InboundReportTab />
								) : (
									<AccessDenied />
								)
							}
						/>
						<Route
							path="/outbound-report/:tab/:subtab"
							element={
								hasAccessPermission("finance-report") ? (
									<OutbountReportTab />
								) : (
									<AccessDenied />
								)
							}
						/>

						<Route
							path="/loan-id/:page/:query"
							element={
								hasAccessPermission("payment-report") ? (
									<LoanIdReport />
								) : (
									<AccessDenied />
								)
							}
						/>
						<Route
							path="/payment-report"
							element={
								hasAccessPermission("payment-report") ? (
									<PaymentReport />
								) : (
									<AccessDenied />
								)
							}
						/>
						<Route
							path="/user-role"
							element={
								hasAccessPermission("user-role") ? (
									<UserRoleManagement />
								) : (
									<AccessDenied />
								)
							}
						/>
						<Route
							path="/lost-after-disbursal/:lead_source"
							element={<LostLeadTab />}
						/>
						<Route
							path="/other-transactions"
							element={
								hasAccessPermission("other-transactions") ? (
									<OtherTransactions />
								) : (
									<AccessDenied />
								)
							}
						/>

						{/* Add a catch-all route */}
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</Suspense>
			</Router>
		);
	}
}

const mapStateToProps = ({ user }) => {
	return {
		loggedIn: user.loggedIn,
	};
};
export default connect(mapStateToProps, null)(CustomRouter);

//export default Routes;
