import secureStorage from "./encrypt";

//GET COUNTRY CODE FROM ENV
let countryCode = process.env.REACT_APP_COUNTRY_CODE;
let appLanguage = process.env.REACT_APP_DEFAULT_LANG;

//IMPORT COUNTRY CONSTANTS
var path = "./" + countryCode + "_config/constant";
var req = require.context("./", true);
var constants = req(path);

//SET COUNTRY CODE IN STORAGE
secureStorage.setItem("countryCode", countryCode);

let configPath = countryCode + "_config/";
secureStorage.setItem("CONFIG_PATH", configPath);
let REACT_APP_GETWAY_API = process.env.REACT_APP_GETWAY_API;
const CONFIG = {
	constants: constants,
	API_URL: {
		GETWAY_API: REACT_APP_GETWAY_API,
		LANG: appLanguage,
		COMMON_GATEWAY_API: REACT_APP_GETWAY_API
	},
};
console.log('CONFIG=============',CONFIG)

export default CONFIG;
