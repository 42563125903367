import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import language_en from "./translations/en/language.json";
import secureStorage from "./config/encrypt";

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";

i18next.init({
	interpolation: { escapeValue: false },
	lng: (secureStorage.getItem("countryCode") === "ph") ? "en" :  "en",
	resources: {
		en: {
			language: language_en,
		}
	},
});
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
	<I18nextProvider i18n={i18next}>
		{/* <React.StrictMode> */}
			<Provider store={store}>
				<App />
			</Provider>
		{/* </React.StrictMode> */}
	</I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
