import { createStore, applyMiddleware, combineReducers } from "redux";
import userReducer from "./reducers/userReducer";
import leadReducer from "./reducers/leadReducer";
import currencyReducer from "./reducers/currencyReducer";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import { NavDetail } from "./InitialState";
const mainReducer = combineReducers({
	user: userReducer,
	lead: leadReducer,
	currency:currencyReducer
});

const commonData = {
	user: {},
	lead: {
		activePage: 1,
		detailPage: NavDetail,
	},
	currency:{}
};

const store = createStore(
	mainReducer,
	commonData,
	composeWithDevTools(applyMiddleware(thunk))
);

export default store;
