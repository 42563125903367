import "./App.scss";
import React, { Component } from "react";
import Routes from "./routes/routes";
import { default as config } from "./config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";

class App extends Component {
	// constructor(props) {
	// 	super(props);
	// }
	componentDidMount = () => {
		// eslint-disable-next-line no-unused-vars
		const favicon = document.getElementById("favicon");
		const title = document.getElementsByTagName("title")[0];

		if (process.env.REACT_APP_COUNTRY_CODE === "en") {
			//favicon.href = "favicon.ico";
		} else {
			//favicon.href = "favicon.ico";
		}

		//SET TITLE
		title.innerHTML = config.constants.TITLE;
	};

	render() {
		return (
			<div className="App">
				<ToastContainer position={toast.POSITION.TOP_RIGHT} />
				<Routes />
			</div>
		);
	}
}

export default App;
